import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { cn } from '../../../../common/utils';
import ButtonSwitchMulti from '../../../../components/actions/ButtonSwitchMulti';
import DropdownSelector from '../../../../components/actions/DropdownSelector';
import {
  assignmentOccupancyMetricSelected,
  assignmentOccupancyMode,
} from '../../../store/occupancy/assignmentOccupancy.state';
import {
  occupancyMetrics,
  OccupancyMode,
} from '../../../store/occupancy/assignmentOccupancy.types';
import useAssignmentOccupancyMetricDescriptors from '../../../store/occupancy/useAssignmentOccupancyMetricDescriptors';

const OccupancyModeSwitch: React.FC = () => {
  const { t } = useTranslation('app');
  const [mode, setMode] = useRecoilState(assignmentOccupancyMode);
  const [metricId, setMetricId] = useRecoilState(
    assignmentOccupancyMetricSelected,
  );

  console.log('assignmentOccupancyMode', assignmentOccupancyMode);
  console.log(
    'assignmentOccupancyMetricSelected',
    assignmentOccupancyMetricSelected,
  );

  const allMetrics = useAssignmentOccupancyMetricDescriptors();
  const allMetricsMap = _.keyBy(allMetrics, m => m.type);

  const viewAs: { key: OccupancyMode; label: string }[] = [
    {
      key: 'volume',
      label: t`Volume`,
    },
    {
      key: 'stockUom',
      label: t`Stock UOM`,
    },
    {
      key: 'maxUom',
      label: t`Max UOM`,
    },
  ];

  return (
    <>
      <DropdownSelector
        DropDown
        DropAlignRight
        buttonTransparent
        policyMode
        // classNameDropDownMenu="w-[5.5rem]"
        className="text-xs"
        values={occupancyMetrics}
        value={metricId}
        renderValue={m => allMetricsMap[m].title}
        onChange={m => setMetricId(m)}
      />
      <ButtonSwitchMulti
        autoSize
        className={cn('mx-0.5 w-full xl:mx-3')}
        classNameLabel={cn('text-xs py-0.5 px-2')}
        buttonType="header"
        options={viewAs}
        selectedIndex={_.findIndex(viewAs, o => o.key === mode)}
        onClick={index => setMode(viewAs[index].key)}
      />
    </>
  );
};

export default OccupancyModeSwitch;
